<template>
  <div>
    <el-menu
      :default-active="active"
      class="pl-2 pr-2"
      mode="horizontal"
      background-color="#f3f3f3"
      :active-text-color="themeColor"
    >
      <template>
        <!-- <el-menu-item index="7" class="menu-item">
          <router-link
            :to="{
              name: 'SATClasses'
            }"
            class="linkInTab"
          >
            <b>
              Courses
            </b>
          </router-link>
        </el-menu-item> -->
        <el-menu-item index="5" class="menu-item">
          <router-link
            :to="{
              name: 'MyClasses'
            }"
            class="linkInTab"
          >
            <b>{{ $t("TeacherCourses.My Classes") }}</b>
          </router-link>
        </el-menu-item>
        <el-menu-item index="6" class="menu-item" v-if="isTeacher">
          <router-link
            :to="{
              name: 'TeacherClasses'
            }"
            class="linkInTab"
          >
            <b>
              {{ $t("TeacherCourses.Classes I Created") }}
            </b>
          </router-link>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";

export default {
  mixins: [Common, role],
  props: ["active"],
  data() {
    return {
      total: 0,
      subjects: [],
      dialogVisible: false
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    }
  },
  watch: {},

  async mounted() {},

  methods: {}
};
</script>

<style scoped>
.item >>> .el-badge__content.is-fixed {
  transform: translateX(100%);
}
.item >>> .el-badge__content {
  line-height: 16px;
}

.linkInTab {
  color: inherit !important;
  display: block;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}

.menu-item {
  position: relative;
  font-size: 16px;
}
</style>
